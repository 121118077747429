import { apiUrl, axios } from '@/api/api.js';
import { PAGE_SIZE } from '@/constants/common';
import { generateErrors } from '@/main/utils/common';

export async function getDistributionData(distributionId) {
    try {
        const { data: { data, included } } = await axios.get(
            `${apiUrl}/form-distribution/${distributionId}?include=organization,organization.parent,template,period`);

        return {
            data: data,
            included: included
        };
    } catch (error) {
        generateErrors(error);
    }
}

export const getDistributionResults = async (distributionId, worksheetId, { calc = 500, version = null }) => {

    try {
        let params = {
            worksheetId: worksheetId,
            calc: calc
        };
        if (version) {
            params['historyId'] = version;
        }
        const { data } = await axios.get(
            `${apiUrl}/form-distribution/${distributionId}/relationships/results`,
            { params }
        );

        return data;
    } catch (error) {
        generateErrors(error);
    }
};

export const saveDistributionResults = async (distributionId, data) => {

    try {
        const { status } = await axios.put(
            `${apiUrl}/form-distribution/${distributionId}/relationships/results`,
            data
        );

        return status;
    } catch (error) {
        generateErrors(error);
    }
};

export const sendDistributionReview = async (distributionId, data = {}) => {
    try {
        const { status } = await axios.post(
            `${apiUrl}/form-distribution/${distributionId}/request-review`,
            data
        );

        return status;
    } catch (error) {
        generateErrors(error);
    }
};

export const submitDistributionReview = async (distributionId, data = {}) => {
    try {
        const { status } = await axios.post(
            `${apiUrl}/form-distribution/submit-review`,
            data
        );

        return status;
    } catch (error) {
        generateErrors(error);
    }
};

export const approveDistributionReview = async (data) => {
    try {
        const { status } = await axios.post(
            `${apiUrl}/form-distribution/approve-review`,
            data
        );

        return status;
    } catch (error) {
        generateErrors(error);
    }
};

export const revokeDistributionApproval = async (data) => {
    try {
        const { status } = await axios.post(
            `${apiUrl}/form-distribution/revoke-approval`,
            data
        );

        return status;
    } catch (error) {
        generateErrors(error);
    }
};

export const sendComment = async (distributionId, worksheetId, comment) => {
    try {
        const { data } = await axios.post(
            `${apiUrl}/form-distribution/${distributionId}/relationships/comments?worksheetId=${worksheetId}`,
            comment
        );

        return data;
    } catch (error) {
        generateErrors(error);
    }
}

export const getWorksheetComments = async (distributionId, worksheetId) => {
    try {
        const { data: { data, included } } = await axios.get(
            `${apiUrl}/form-distribution/${distributionId}/relationships/comments?worksheetId=${worksheetId}&include=author,thread`);

        return {
            data: data,
            included: included
        };
    } catch (error) {
        generateErrors(error);
    }
};

export const getDistributionVersions = async (distributionId, worksheetId) => {
    try {
        const { data: { data, included } } = await axios.get(
            `${apiUrl}/form-distribution/${distributionId}/relationships/history?worksheetId=${worksheetId}&include=createdBy`);

        return {
            data: data,
            included: included
        };
    } catch (error) {
        generateErrors(error);
    }
};

export const getFormDistribution = async ({
    page = 1,
    pageSize = PAGE_SIZE,
    include = '',
    sortParametr = '',
    filter = {},
}) => {
    const params = {
        page,
        'page-size': pageSize,
        include,
        filter: JSON.stringify(filter)
    }
    if (sortParametr.length) params.sort = sortParametr;
    try {
        const { data } = await axios.get(`${apiUrl}/form-distribution`, {
            params
        })
        return data;
    } catch (error) {
        generateErrors(error)
    }
}


export async function getDistributionListData({ pageNumber = 1, pageSize = PAGE_SIZE, filter = {}, sort = '-period.activeFrom' }) {
    try {
        const dataToServer = {
            filter,
            include: 'organization.parent,organization.children,organization.location,organization.district,organization.type,organization.subordinateTo,period,template.form.distributionInterval,template.worksheets.group,worksheets.worksheet.group',
            page: pageNumber,
            'page-size': pageSize,
            sort
        };
        const { data } = await axios.post(`${apiUrl}/form-distribution/list`, dataToServer);
        return {
            list: [...data.data],
            included: data.included ? data.included : [],
            meta: data.meta,
        };
    } catch (error) {
        generateErrors(error);
    }
}

export const getDistributionWorksheetsStatuses = async (formDistributionId) => {
    try {
        const { data } = await axios.get(
            `${apiUrl}/form-distribution/${formDistributionId}/relationships/worksheets?page=1&page-size=5000&include=worksheet`);
        return data.data;
    } catch (error) {
        generateErrors(error);
    }
}

export const sendUnloadingData = async (formDistributionId, reportTemplateId) => {
    try {
        return await axios.post(`${ apiUrl }/form-distribution/${ formDistributionId }/report/${ reportTemplateId }`);
    } catch (error) {
        generateErrors(error);
    }
}

export const aggregateDistribution = async (formDistributionId) => {
    try {
        const request = await axios.post(`${apiUrl}/form-distribution/${formDistributionId}/aggregate`);

        return request;
    } catch (error) {
        generateErrors(error);
    }
};

export const restoreDistribution = async (formDistributionId, version) => {
    try {
        const request = await axios.post(`${apiUrl}/form-distribution/${formDistributionId}/restore/${ version }`);

        return request;
    } catch (error) {
        generateErrors(error);
    }
};

export const resetDistribution = async (formDistributionId) => {
    try {
        const request = await axios.post(`${apiUrl}/internal/reset-distribution/${formDistributionId}`);

        return request;
    } catch (error) {
        generateErrors(error);
    }
};

export const getDistributionCheck = async (formDistributionId, worksheets) => {
    try {
        const { data } = await axios.get(`${ apiUrl }/form-distribution/${ formDistributionId }/check?${ worksheets }`);

        return {
            errors: [...data.data],
            included: data.included ? data.included : [],
            meta: data.meta,
        };
    } catch (error) {
        generateErrors(error);
    }
}

export async function findOrganizations({ page = 1, pageSize = PAGE_SIZE, filter = {}, include = '' }) {
    const params = {
        filter: JSON.stringify(filter),
        page: page,
        'page-size': pageSize,
        include
    };

    try {
        return await axios.get(`${apiUrl}/form-distribution/find-organizations`, { params });
    } catch (error) {
        generateErrors(error);
    }
}

export const downloadDistributionReport = async (formDistributionId, reportTemplateId, historyId = null) => {
    try {
        let params = '';
        if (historyId) {
            params = `?historyId=${ historyId }`;
        }

        return await axios.post(`${ apiUrl }/form-distribution/${ formDistributionId }/report/${ reportTemplateId }${ params }`);
    } catch (error) {
        generateErrors(error);
    }
}

export const uploadData = async (formDistributionId, file) => {
    try {
        return await axios.post(`${ apiUrl }/form-distribution/${ formDistributionId }/upload`, file, {
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        });
    } catch (error) {
        generateErrors(error);
    }
}

export const deleteComment = async (commentId) => {
    try {
        const { data } = await axios.delete(`${ apiUrl }/comment/${ commentId }`);

        return data.data;
    } catch (error) {
        generateErrors(error);
    }
};

export async function patchComment(commentId, data) {
    try {
        const response = await axios.patch(`${apiUrl}/comment/${commentId}`, data);

        return response;
    } catch (error) {
        generateErrors(error);
    }
}
